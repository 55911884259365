import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Image from "../components/image"

const NotFoundPage = () => (
  <Layout type="home">
    <SEO title="404: Not found" />
    <div id="content">
      <section id="menu" className="cSection">
        <div id="gintonic" className="cRecommends">
        <figure className="bg"><Image filename="bg_gin@2x.jpg" alt="" /></figure>
          <div className="cInnerArea">
            <h2 className="rTitle">
            <span className="catch">404: Not Found</span>
              <span className="title">お探しのページは<br className="cSP"/>存在しない様です。</span>
            </h2>

            <h1 className="rTypography">Not Found&nbsp;</h1>
            <div className="rTextArea">
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
              <p>お探しのページ・画像・コンテンツなどは当サイト内には存在しない様です。</p>
              <p>お手数ですが、サイトTOPからもう一度お探しください。</p>
              <p>残念ながらお見せするコンテンツが見つかりませんので、ここにはヨイチのプロフィールを置いておきます。</p>
              <p className="btn1"><Link to="/">
                <Button>酒場元木TOPへ</Button>
              </Link></p>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="cSection">
        <div className="cInnerArea">
          <div className="cProfile">
          <figure className="cPC"><Image filename="prof_41@2x.jpg" alt="元木ヨイチ" /></figure>
            <h2 className="pTitle">
              <span className="ja">元木 ヨイチ</span>
              <span className="en">Yoichi Motoki</span>
            </h2>
            <div className="pTextArea">
            <figure className="cSP"><Image filename="prof_41@2x.jpg" alt="元木ヨイチ" /></figure>
              <p className="cLiner">プロフィール</p>
              <p className="pText">バブル期の銀座にてバーテンダーとしてスタート。<br/>
              銀座の老舗バー「セント・サワイ・オリオンズ」に入店し、世界的バーテンダー「澤井慶明」に師事。</p>
              <p className="pText">30歳のときに英国就労ビザを取得しスコットランドのアラン蒸溜所にてスチルマンとして勤務。<br/>
              スコットランド2年間でアラン蒸溜所に勤めながら、スプリングバンク蒸溜所、ザ・スコッチ・モルト・ウイスキー・ソサエティー本部にて勤務。<br/>
              帰国後は、スコッチウイスキーの商社「株式会社ウィスク・イー」に入社。テイスティング会講師やイベントディレクターとして勤務。<br/>
              ブリュードッグ・ジャパンに出向しシニアマネージャーとして勤務。</p>
              <p className="pText">京都蒸溜所を設立する仕事で京都に移住。<br/>
              季の美の開発に携わり、その後独立。<br/>
              洋酒業界に携わって30年以上になります。</p>
            </div>
          </div>
          <div className="cInnerArea">
          &nbsp;
          </div>
        </div>
      </section>
    </div>
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Ojisan />
    </div>
    <Tst siteTitle="fdsafdafafds" />
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>

)

export default NotFoundPage
